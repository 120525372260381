<template>
  <div>
    <el-container>
      <el-aside class="home-aside" style="width: 240px;">
        <SideBar />
      </el-aside>
      <el-main style="background-color: #F8F8F8;height: 100vh;width: 100%;padding: 0px">
        <div style="height: 100vh;overflow-y: auto;">
          <router-view/>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import SideBar from '../components/SideBar.vue'
export default {
  name: "Home",
  components: {
    SideBar
  },
  data() {
    return {
      menus: [
        {
          title: 'My Refffs',
          icon: require('../assets/icon_notecard.png'),
          selected: true,
          path: '/home/my-refffs'
        },
        {
          title: 'External Inspirations',
          icon: require('../assets/icon_notecard.png'),
          selected: false,
          path: '/home/my-archive'
        },
      ]
    }
  },
  mounted(){
    let path = this.$route.path
    this.menus.forEach((item, index) => {
      item.selected = item.path === path
    })
  },
  methods: {
   
    handleMenuItemClick(index) {
      let path = this.menus[index].path
      this.menus.forEach((item, i) => {
        item.selected = i === index
      })
      this.$router.push(path)
    },
  }
}
</script>

<style scoped lang="less">

.home-aside {
  height: 100vh;
  background: white;
  box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);
  border-radius: 0px 0px 0px 0px;
}

.menu-item {
  margin-top: 15px;
  cursor: pointer;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  color: #FFC569;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;

  &:hover {
    background-color: #EFF1FF;
  }
}

.menu-item-selected {
  color: #FFC569;
  background-color: #EFF1FF;
}
</style>
