<template>
  <div>
    <aside
      id="default-sidebar"
      class="sidebar-container fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div class="h-full px-4 py-4 overflow-y-auto">
        <div class="px-2 pt-4 pb-6 logo-container">
          <img class="logo" src="../assets/main_logo.png" />
        </div>
        <ul class="space-y-2 font-medium pt-4">
          <li
            :class="[
              'menu-item hover:bg-gray-100 flex items-center',
              { active: menu.selected },
            ]"
            v-for="(menu, index) in menus"
            :key="index"
            @click="handleMenuItemClick(menu)"
          >
            <img
              class="menu-icon"
              :src="
                require(`../assets/${menu.icon}${
                  menu.selected ? '_selected' : ''
                }.png`)
              "
            />
            <span class="ms-3 menu-text">{{ menu.title }}</span>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      menus: [
        {
          title: "My Refffs",
          icon: "article",
          selected: true,
          path: "/home/my-refffs",
          subPath: ["/home/view-refff"],
        },
        {
          title: "External Inspirations",
          icon: "archive",
          selected: false,
          path: "/home/my-archive",
          subPath: ["/home/archive-detail"],
        },
        {
          title: "Agent",
          icon: "archive",
          selected: false,
          path: "/home/my-agent-service",
          subPath: [],
        },
      ],
    };
  },
  computed: {},
  watch: {
    $route() {
      this.menus = this.menus.map((item) => ({
        ...item,
        selected:
          item.path === this.$route.path ||
          item.subPath.includes(this.$route.path),
      }));
    },
  },
  mounted() {
    this.menus = this.menus.map((item) => ({
      ...item,
      selected: item.path === this.$route.path,
    }));
  },
  methods: {
    handleMenuItemClick(item) {
      this.$router.push(item.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-container {
  width: 250px;
  background: #f9f9f9;
  .logo-container {
    border-bottom: 1px solid #e2e8f0;
  }
  .logo {
    width: 104px;
    height: 32px;
  }
  .menu-item {
    color: #718096;
    padding: 14px;
    cursor: pointer;
    border-radius: 12px;
    font-size: 14px;
    &.active {
      color: white;
      background: #ec652b;
      font-weight: bold;
    }
    .menu-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}
</style>
